import React from "react";
import { Link } from "react-scroll";
import Pagetitle from "../elements/Pagetitle";
import Service from "../elements/Service";

const servicesData = [
  {
    id: 1,
    icon: "images/apps-lg.png",
    title: "App Development",
    content:
      "Build a wonderful responsive application for your startup!",
    color: "#6C6CE5",
    contentColor: "light",
    section: 'section-estimate-android'
  },
  {
    id: 2,
    icon: "images/service-2.svg",
    title: "Web Development",
    content:
      "Want to have an online presence? Let's build a Mobile First Website for your business.",
    color: "#F9D74C",
    contentColor: "dark",
    section: 'section-estimate-web'
  },
  {
    id: 3,
    icon: "images/machine-repair.png",
    title: "Upgrade/Fix",
    content:
      "Already have a website or an app? Want to upgrade or fix something?",
    color: "#F97B8B",
    contentColor: "light",
    section: 'section-estimate-backend'
  },
];

function Services() {
  return (
    <section id="services">
      <div className="container">
        <Pagetitle title="Services" />
        <div className="row fix-spacing">
          {servicesData.map((service) => (
            <div className="col-md-4" style={{ cursor: 'pointer' }} key={service.id}>
              <Link to={service.section} spy={true}
                smooth={true}
                duration={500}>
                <Service service={service} />
              </Link>

            </div>
          ))}
        </div>
        <div className="mt-5 text-center">
          <p className="mb-0">
            Looking for a custom job?{" "}
            <Link
              className="colorpink pointer"
              to="section-contact"
              spy={true}
              smooth={true}
              duration={500}
            >
              Click here
            </Link>{" "}
            to contact me! 👋
          </p>
        </div>
      </div>
    </section>
  );
}

export default Services;
