import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
// import './analytics/analytics';
import "./App.scss";
import ActionProvider from "./context/action";
import JennaProvider from "./context/jenna";
import Homepage from "./pages/Homepage";
import useAnalytics from "./analytics/useAnalytics";



function App()
{

  useAnalytics({
    projectId: '6708ece28c0051bcaf45de05'
  })

  return (
    <BrowserRouter>
      <ActionProvider>
        <JennaProvider>
          <Switch>
            <Route path="/" exact>
              <Homepage />
            </Route>

          </Switch>
        </JennaProvider>
      </ActionProvider>
    </BrowserRouter>

  );
}

export default App;
