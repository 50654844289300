import React, { useState, useEffect } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Link } from "react-scroll";
import Pagetitle from "../elements/Pagetitle";
import Portfolio from "../elements/Portfolio";

const filters = [
  {
    id: 1,
    text: "All",
  },
  {
    id: 2,
    text: "Web",
  },
  {
    id: 3,
    text: "Android",
  },
  {
    id: 4,
    text: "Backend"
  }
];

const allData = [
  {
    id: 1,
    title: "Mail Man",
    category: "Backend",
    image: "images/works/mailman.jpg",
    link: 'https://www.npmjs.com/package/@js-cloud/mailman',
  },
  {
    id: 2,
    title: "FlashQ",
    category: "Backend",
    image: "images/works/flashq.png",
    link: 'https://www.npmjs.com/package/@js-cloud/flashq'
  },
  {
    id: 3,
    title: "JS Cloud Suite",
    category: "Backend",
    image: "images/works/js-cloud-suite/js-cloud-suite.jpg",
    link: 'https://github.com/jscloud-org',
  },
  {
    id: 4,
    title: "Helping Hands",
    category: "Android",
    image: 'images/works/helping-hands/feature-image.png',
    popupLink: ['images/works/helping-hands/feature-gif.gif'],
  },
  {
    id: 5,
    title: "JS Vault",
    category: "Android",
    image: "images/works/js-vault/feature-image.png",
    link: 'https://play.google.com/store/apps/details?id=com.jstechnologies.jsvaultv30.free&hl=en_IN&gl=US',
  },
  {
    id: 6,
    title: "JS Notes",
    category: "Web",
    image: "images/works/js-notes/js-notes.jpg",
    link: "https://notes.jscloud.in",
  },
  {
    id: 7,
    title: "JS Trivia",
    category: "Web",
    image: "images/works/quiz-app/feature-image.png",
    link: "https://jerrysjoseph.github.io/React_QuizApp_Client/",
  },
  {
    id: 8,
    title: "Find Me",
    category: "Android",
    image: "images/works/Find-me/feature-image.png",
    popupLink: ['images/works/Find-me/feature-image.png'],
  },
  {
    id: 9,
    title: "Realtime Chat",
    category: "Web",
    image: "images/works/realtime-chat/feature-image.png",
    link: 'https://jerrysjoseph.github.io/React_realtime_chat_client/',
  },
  {
    id: 10,
    title: "Exams On Us",
    category: "Android",
    image: "images/works/examsonus/feature-image.png",
    popupLink: ['images/works/examsonus/feature-image.png'],
  },
  {
    id: 11,
    title: "Advanced Chess Game",
    category: "Web",
    image: "images/works/chessgame/feature-image.png",
    link: 'https://chess.jscloud.in/',
  },
  {
    id: 12,
    title: "Kanoodle Game",
    category: "Web",
    image: "images/works/kanoodle/feature-image.png",
    link: 'https://kanoodle.jscloud.in/',
  },
  {
    id: 13,
    title: "TravelBuddy",
    category: "Web",
    image: "images/works/travelbuddy/feature-image.png",
    link: 'https://travelbuddy.jscloud.in/',
  },
];

function Works({ isJenna = false, onCloseJenna = () => { }, dataVisibleCount = 6, dataIncrement = 3 })
{

  const [activeFilter, setActiveFilter] = useState('All');
  const [_maxVisibleCount, setMaxVisibleCount] = useState(dataVisibleCount);
  const [visibleItems, setVisibleItems] = useState(allData);



  useEffect(() =>
  {
    const filterVisibleItems = (filter) =>
    {
      let data = [];
      if (filter === 'All') {
        data = allData.filter(item => (item.id <= _maxVisibleCount));;
      }
      else
        data = allData.filter(item => (item.category.toLowerCase() === filter.toLowerCase()) && (visibleItems.length <= _maxVisibleCount));
      setVisibleItems(data);
    }
    if (activeFilter)
      filterVisibleItems(activeFilter);

  }, [activeFilter, _maxVisibleCount, visibleItems.length]);






  const handleChange = (e) =>
  {
    setActiveFilter(e.target.value)
  };

  const handleLoadmore = (e) =>
  {
    setMaxVisibleCount(_maxVisibleCount + dataIncrement)
  };

  const noMoreData = () => (allData.length === visibleItems.length) || activeFilter !== 'All'

  if (isJenna)
    return <>
      {/* Start Portfolio Items */}
      <div className="row portfolio-wrapper">
        {visibleItems.map((item) => (
          <div className="col-md-4 col-sm-6 grid-item" key={item.id}>
            <Portfolio portfolio={item} />
          </div>
        ))}
      </div>
      {/* End Portfolio Items */}

      <div className="load-more text-center mt-4">
        <Link
          to="section-works"
          className="btn btn-default"
          onClick={onCloseJenna}
        >
          <span>
            <i className="fas fa-spinner"></i> See more
          </span>
        </Link>
      </div></>

  return (
    <section id="works">
      <div className="container">
        <Pagetitle title="Recent Projects" />
        {/* Start Portfolio Filters */}
        <ScrollAnimation
          animateIn="fadeInUp"
          animateOut="fadeInOut"
          animateOnce={true}
        >
          <ul className="portfolio-filter list-inline">
            {filters.map((filter) => (
              <li className="list-inline-item" key={filter.id}>
                <button
                  onClick={handleChange}
                  value={filter.text}
                  className={
                    filter.text === activeFilter
                      ? "text-capitalize current"
                      : "text-capitalize"
                  }
                >
                  {filter.text}
                </button>
              </li>
            ))}
          </ul>
        </ScrollAnimation>
        {/* End Portfolio Filters */}

        {/* Start Portfolio Items */}
        <div className="row portfolio-wrapper">
          {visibleItems.map((item) => (
            <div className="col-md-4 col-sm-6 grid-item" key={item.id}>
              <Portfolio portfolio={item} />
            </div>
          ))}
        </div>
        {/* End Portfolio Items */}

        <div className="load-more text-center mt-4">
          <button
            className="btn btn-default"
            onClick={handleLoadmore}
            disabled={noMoreData() ? "disabled" : null}
          >
            {noMoreData() ? (
              "No more items"
            ) : (
              <span>
                <i className="fas fa-spinner"></i> Load more
              </span>
            )}
          </button>
        </div>
      </div>
    </section>
  );
}

export default Works;
