// useAnalytics.js
import { useEffect, useRef, useCallback } from 'react';

const useAnalytics = (config) =>
{
    const API_BASE_URL = config.apiBaseUrl || (`${ process.env.NODE_ENV === 'production' ? 'https://analytics.jscloud.in' : 'http://localhost:3000' }/api/v1/session`);
    const PROJECT_ID = config.projectId
    const ACTIVITY_TRACKING_TIMEOUT = config.activityTrackingTimeout || (process.env.NODE_ENV === 'production' ? 15 * 60 * 1000 /*15 mins */ : 5 * 60 * 1000/* 5mins*/);

    if (!PROJECT_ID)
        throw new Error('No project-id specified. Please specify Project id in the global config.');

    const sessionIdRef = useRef(null);
    const activityTimeoutRef = useRef(null);

    // Helper function to send requests
    const apiRequest = useCallback(async (url, method, data) =>
    {
        try {
            const response = await fetch(url, {
                method,
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                throw new Error('API request failed');
            }

            return await response.json();
        } catch (err) {
            console.error('Session Tracker API Error:', err);
        }
    }, []);

    const saveSessionId = (id) =>
    {
        localStorage.setItem('sessionId', id);
        sessionIdRef.current = id; // Update the ref
    };

    const retrieveSessionId = () => localStorage.getItem('sessionId');

    const clearSessionId = () =>
    {
        localStorage.removeItem('sessionId');
        sessionIdRef.current = null; // Clear the ref
    };

    const getVisitorId = () =>
    {
        let visitorId = localStorage.getItem('visitorId');
        if (!visitorId) {
            visitorId = 'visitor-' + Math.random().toString(36).substr(2, 9);
            localStorage.setItem('visitorId', visitorId);
        }
        return visitorId;
    };

    const createSession = useCallback(async () =>
    {
        const sessionId = retrieveSessionId();
        if (sessionId) return;

        const sessionData = {
            visitorId: getVisitorId(),
            project: PROJECT_ID,
            referrer: document.referrer || 'Direct',
            pageUrl: window.location.pathname,
            pageTitle: document.title,
            userAgent: navigator.userAgent,
        };

        const result = await apiRequest(`${ API_BASE_URL }/create`, 'POST', sessionData);
        if (result && result.sessionId) {
            saveSessionId(result.sessionId);
            resetActivityTimer();
        }
    }, [apiRequest, PROJECT_ID]);

    const updateSession = useCallback(async () =>
    {
        const sessionId = retrieveSessionId();
        if (!sessionId) {
            return createSession();
        }

        const updateData = {
            exitPage: window.location.pathname,
            pageUrl: window.location.pathname,
            pageTitle: document.title,
        };

        await apiRequest(`${ API_BASE_URL }/update/${ sessionId }`, 'PUT', updateData);
    }, [apiRequest, createSession]);

    const endSession = useCallback(async () =>
    {
        const sessionId = retrieveSessionId();
        if (!sessionId) return;

        const url = `${ API_BASE_URL }/end/${ sessionId }`;

        // Use navigator.sendBeacon for better performance
        if (navigator.sendBeacon) {
            navigator.sendBeacon(url);
        } else {
            await apiRequest(url, 'OPTIONS');
        }

        clearSessionId();
    }, [apiRequest]);

    const resetActivityTimer = useCallback(() =>
    {
        if (activityTimeoutRef.current) clearTimeout(activityTimeoutRef.current);

        activityTimeoutRef.current = setTimeout(async () =>
        {
            await endSession();
        }, ACTIVITY_TRACKING_TIMEOUT);
    }, [endSession]);

    const trackUserActivity = useCallback(() =>
    {
        const events = ['mousemove', 'keydown', 'scroll', 'click'];
        const handleActivity = resetActivityTimer;

        events.forEach(eventType =>
        {
            window.addEventListener(eventType, handleActivity);
        });

        return () =>
        {
            events.forEach(eventType =>
            {
                window.removeEventListener(eventType, handleActivity);
            });
        };
    }, [resetActivityTimer]);

    useEffect(() =>
    {
        const handlePageLoad = async () =>
        {
            const sessionId = retrieveSessionId();
            if (!sessionId) {
                await createSession();
            } else {
                await updateSession();
            }
        };

        const handleVisibilityChange = async () =>
        {
            if (document.visibilityState === 'hidden') {
                await endSession();
            } else if (document.visibilityState === 'visible') {
                await handlePageLoad();
            }
        };

        window.addEventListener('load', handlePageLoad);
        window.addEventListener('visibilitychange', handleVisibilityChange);
        const cleanupActivityTracking = trackUserActivity();

        return () =>
        {
            window.removeEventListener('load', handlePageLoad);
            window.removeEventListener('visibilitychange', handleVisibilityChange);
            cleanupActivityTracking();
            if (activityTimeoutRef.current) clearTimeout(activityTimeoutRef.current);
        };
    }, [createSession, updateSession, trackUserActivity]);

};

export default useAnalytics;
