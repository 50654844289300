import React from "react";
import Pagetitle from "../elements/Pagetitle";
import Timeline from "../elements/Timeline";


const experienceData = [
  {
    id: 0,
    title: "Software Developer",
    years: "2023 - Present",
    content:
      "Timewatch PLC",
  },
  {
    id: 1,
    title: "Full Stack Developer",
    years: "2020 - 2023",
    content:
      "Freelancer",
  },
  {
    id: 2,
    title: "Member",
    years: "2020 - 2023",
    content:
      "Google Developers Group, Chandigarh",
  },
  {
    id: 3,
    title: "Co-Founder & CTO",
    years: "2018-2020",
    content:
      "The Fabs",
  },
];

function Experiences({ isJenna = false })
{
  if (isJenna)
    return (
      <div className="col-12">
        <div className="spacer d-md-none d-lg-none" data-height="30"></div>
        <div className="timeline exp bg-white rounded shadow-dark padding-30 overflow-hidden">
          {experienceData.map((experience) => (
            <Timeline key={experience.id} education={experience} />
          ))}
          <span className="line"></span>
        </div>
      </div>
    )
  return (
    <section id="experience">
      <div className="container">
        <Pagetitle title="Experience" />
        <div className="row" >
          <div className="col-12">
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
            <div className="timeline exp bg-white rounded shadow-dark padding-30 overflow-hidden">
              {experienceData.map((experience) => (
                <Timeline key={experience.id} education={experience} />
              ))}
              <span className="line"></span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Experiences;
